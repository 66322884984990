//import axios from "../utils/axios";
import Axios from "axios";

function buscarPorIdPessoa(idPessoaUsuario) {
  return new Promise((resolve, reject) => {
    Axios.get(`/usuario/buscarPorIdPessoa/${idPessoaUsuario}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function desabilitarMFA(idUsuario) {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/desabilitarMFA/${idUsuario}`, null)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function changeTheme(theme) {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/changeTheme`, theme)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function mfaEnable() {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/mfaEnable`, null)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function validarCodigoMFA(codigo) {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/verifyMFACode`, codigo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function mfaDisable() {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/mfaDisable`, null)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function relatorioUsuarios(papel, status, statusAcessoUsuario,perfil, promotora, pontoVenda,convenio, page) {
  return new Promise((resolve, reject) => {
    const vo = {
      convenio,
      promotora, pontoVenda,
      papel,
      status,
      statusAcessoUsuario,
      perfil
    };

    Axios.post(`/usuario/relatorioUsuarios/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function newUser(pessoa, senha, perfil) {
  return new Promise((resolve, reject) => {
    const vo = {
      pessoa,
      perfil,
      passwordUsuario: senha,
    };

    Axios.post(`/usuario`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarPerfil(idUsuario, perfil) {
  return new Promise((resolve, reject) => {
    Axios.put(`/usuario/${idUsuario}`, perfil)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarSenha(idUsuario, senha) {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/alterarSenha/${idUsuario}`, senha)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarStatus(idUsuario, status) {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/alterarStatus/${idUsuario}`, status)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarVigencia(idUsuario, vigencia) {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/alterarVigencia/${idUsuario}`, vigencia)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function recuperarSenha(object) {
  return new Promise((resolve, reject) => {
    Axios.post(`/usuario/recuperarSenha`, JSON.stringify(object))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarCodigoRecuperacaoSenha(hash) {
  return new Promise((resolve, reject) => {
    Axios.get(`/usuario/buscarCodigoRecuperacaoSenha/${hash}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarSenhaUserLogado(newPassword, oldPassword) {
  return new Promise((resolve, reject) => {
    const vo = {
      newPassword,
      oldPassword,
    };

    Axios.post(`/usuario/alterarSenha`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function changeCompany(idEmpresa) {
  return new Promise((resolve, reject) => {
    Axios.get(`/usuario/mudarEmpresa/${idEmpresa}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function removerRestricaoExcessoErrosSenha(idUsuario) {
  return new Promise((resolve, reject) => {
    Axios.get(`/usuario/removerRestricaoExcessoErrosSenha/${idUsuario}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const UsuarioService = {
  buscarPorIdPessoa,
  newUser,
  alterarStatus,
  alterarSenha,
  alterarSenhaUserLogado,
  alterarVigencia,
  alterarPerfil,
  changeCompany,
  buscarCodigoRecuperacaoSenha,
  recuperarSenha,
  relatorioUsuarios,
  removerRestricaoExcessoErrosSenha,
  mfaDisable,
  validarCodigoMFA,
  mfaEnable,
  desabilitarMFA,
  changeTheme
};

export default UsuarioService;
