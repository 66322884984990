// Theme
import {
  BsInputCursorText,
  BsStarHalf,
  BsCalendarDate,
  BsListOl,
  BsTextarea,
} from "react-icons/bs";
import { ImSortNumbericDesc } from "react-icons/im";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { VscListSelection } from "react-icons/vsc";

export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  BLUE: "BLUE",
  INDIGO: "INDIGO",
  YELLOW: "YELLOW",
  CYAN: "CYAN",
  PINK: "PINK",
  TEAL: "TEAL",
  ORANGE: "ORANGE",
  BLUE_GRAY: "BLUE_GRAY",
  PURPLE: "PURPLE",
  RED: "RED",
};
// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
export const CHANGE_SELFIE_USER = "CHANGE_SELFIE_USER";
export const CHANGE_EMPRESA = "CHANGE_EMPRESA";
export const CHANGE_PREFERENCES_DASHBOARD = "CHANGE_PREFERENCES_DASHBOARD";
export const CHANGE_LAST_ACCESS = "CHANGE_LAST_ACCESS";
export const CHANGE_MFA = "CHANGE_MFA";
export const CHANGE_NOTIFICACOES = "CHANGE_NOTIFICACOES";

export const SITUACAO_EMPREGADO = [
  {
    cod: 'ATIVO',
    name: 'Ativo'
  },
  {
    cod: 'INATIVO_APOSENTADO',
    name: 'Inativo/Aposentado'
  },
  {
    cod: 'PENSIONISTA',
    name: 'Pensionista'
  }
]


export const REGIME_JURIDICO = [
  {
    cod: 'CLT',
    name: 'CLT'
  },
  {
    cod: 'ESTATUTARIO',
    name: 'Estatutário'
  }
]

export const langHighcharts = {
  decimalPoint: ",",
  thousandsSep: ".",
  contextButtonTitle: "Menu do Gráfico",
  downloadCSV: "Download CSV",
  downloadJPEG: "Download JPEG",
  downloadPDF: "Download PDF",
  downloadPNG: "Download PNG",
  downloadSVG: "Download SVG",
  downloadXLS: "Download XLS",
  drillUpText: "Voltar Para {series.name}",
  loading: "Carregando...",
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  navigation: {
    popup: {
      addButton: "Adicionar",
      background: "Fundo",
    },
  },
  tooltip: {
    yDecimals: 2,
  },
  noData: "Nenhum dado para exibir",
  openInCloud: "Abrir no Highcharts Cloud",
  printChart: "Imprimir Gráfico",
  viewFullscreen: "Visualizar em tela cheia",
  resetZoom: "Resetar zoom",
  resetZoomTitle: "Resetar zoom nível 1:1",
  shortMonths: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  viewData: "Visualizar dados",
  weekdays: [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ],
};

export const statusContrato = [

  {
    name: "Cadastro",
    value: "AGUARDANDO_EMISSAO_CCB",
  },
  {
    name: "Aguardando Pagamento",
    value: "AGUARDANDO_PAGAMENTO",
  },
  {
    name: "Analise Formalização",
    value: "ANALISE_FORMALIZACAO",
  },
  {
    name: "Assinatura Concluida",
    value: "ASSINATURA_CONCLUIDA",
  },
  {
    name: "Cancelada",
    value: "CANCELADA",
  },
  {
    name: "CCB Emitida",
    value: "CCB_EMITIDA",
  },
  {
    name: "Cedida",
    value: "CEDIDA",
  },
  {
    name: "Finalizada",
    value: "FINALIZADA",
  },
  {
    name: "Liberada",
    value: "LIBERADA",
  },
  {
    name: "Link Assinatura",
    value: "LINK_ASSINATURA",
  },
  {
    name: "Paga",
    value: "PAGA",
  },
  {
    name: "Pendenciada",
    value: "PENDENCIADA",
  },
  {
    name: "Pendente de Pagamento",
    value: "PENDENTE_PAGAMENTO",
  },
  {
    name: "Reanalise Formalização",
    value: "REANALISE_FORMALIZACAO",
  }
];


export const tipoContrato = [

  {
    name: "Normal",
    value: "NORMAL",
  },
  {
    name: "Autocontratação",
    value: "AUTOCONTRATACAO",
  },
  {
    name: "Compra de Dívida",
    value: "COMPRA_DIVIDA",
  }
];



export const statusSimulacao = [
  {
    name: "Simulação",
    value: "SIMULACAO",
  },
  {
    name: "Contratação",
    value: "CONTRATACAO",
  },
  {
    name: "Digitação Dos Dados Pessoais",
    value: "DIGITACAO_DADOS_PESSOAIS",
  },
  {
    name: "Digitação do Endereço",
    value: "DIGITACAO_ENDERECO",
  },
  {
    name: "Digitação dos Dados Bancários",
    value: "DIGITACAO_DADOS_BANCARIOS",
  },
  {
    name: "Contratação Realizada",
    value: "CONTRATACAO_REALIZADA",
  },
];

export const TIPO_CONTA = [
  {
    cod: "CORRENTE",
    name: "Conta Corrente",
  },
  {
    cod: "POUPANCA",
    name: "Conta Poupança",
  },
];


export const TIPO_VALOR_SPLIT = [
  {
    cod: "SPLIT_ORIGINADOR",
    name: "Split Originador",
  },
  {
    cod: "SPLIT_FIDC",
    name: "Split FIDC",
  },
  {
    cod: "SPLIT_SOMADA",
    name: "Split Somada",
  },
  {
    cod: "SPLIT_MANUAL",
    name: "Split Compra de Dívida",
  },
  
  {
    cod: "SPLIT_CORBAN",
    name: "Split Corban",
  },
  {
    cod: "INTERVENIENTE",
    name: "Split Interveniente",
  },
];

export const TIPO_CHAVE = [
  {
    cod: "CPF",
    name: "CPF",
  },
  {
    cod: "CNPJ",
    name: "CNPJ",
  },
  {
    cod: "EMAIL",
    name: "Email",
  },
  {
    cod: "TELEFONE",
    name: "Telefone",
  },
  {
    cod: "ALEATORIO",
    name: "Chave Aleatória",
  },

];

export const RACA = [
  {
    cod: "NENHUM",
    name: "Nenhum",
  },
  {
    cod: "INDIGENA",
    name: "Indígena",
  },
  {
    cod: "BRANCA",
    name: "Branca",
  },
  {
    cod: "PRETA",
    name: "Preta",
  },
  {
    cod: "AMARELA",
    name: "Amarela",
  },
  {
    cod: "PARDA",
    name: "Parda",
  },
];

export const ESTADO_CIVIL = [
  {
    cod: "CASADO",
    name: "Casado",
  },
  {
    cod: "SOLTEIRO",
    name: "Solteiro",
  },
  {
    cod: "SEPARADO",
    name: "Separado",
  },
  {
    cod: "VIUVO",
    name: "Viúvo",
  },
  {
    cod: "DIVORCIADO",
    name: "Divorciado",
  },
  {
    cod: "UNIAO_ESTAVEL",
    name: "União Estável",
  },
];


export const TIPO_TABELA = [
  {
    cod: "NORMAL",
    name: "Normal",
  },
  {
    cod: "COMPRA_DIVIDA",
    name: "Compra de Dívida",
  },
  {
    cod: "REFINANCIAMENTO",
    name: "Refinanciamento",
  },
];

export const SEXO = [
  {
    cod: "MASCULINO",
    name: "Masculino",
  },
  {
    cod: "FEMININO",
    name: "Feminino",
  }
];

export const ESCOLARIDADE = [
  "Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou",
  "Até o 5º ano incompleto do Ensino Fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular",
  "5º ano completo do Ensino Fundamental",
  "Do 6º ao 9º ano do Ensino Fundamental incompleto (antiga 5ª à 8ª série)",
  "Ensino Fundamental completo",
  "Ensino Médio incompleto",
  "Ensino Médio completo",
  "Educação Superior incompleta",
  "Educação Superior completa",
  "Mestrado completo",
  "Doutorado completo",
  "Pós Graduação completa",
  "Técnico completo",
  "Técnologo completo",
  "Pós Graduação incompleta",
  "Mestrado incompleto",
  "Doutorado incompleto",
  "Técnico incompleto",
  "Técnologo incompleto",
];

export const RELACAO = [
  "Cônjuge",
  "Companheiro com filhos e/ou união estável",
  "Filho ou enteado até 21 anos",
  "Filho ou enteado universitário ou escola técnica",
  "Irmão(ã), neto(a) ou bisneto(a) com guarda",
  "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola ténica",
  "Pais, avós e bisavós",
  "Menor pobre, até 21 anos",
  "Incapaz",
  "Companheiro sem filhos e/ou união estável",
  "Agregado/Outros",
  "Ex cônjuge que receba pensão de alimentos",
  "Filhos maiores de 24 anos",
];

export const BANCO = [
  {
    cod: "BANCO_DO_BRASIL",
    name: "Banco do Brasil S.A."
  },
  {
    cod: "BANCO_DA_AMAZONIA",
    name: "Banco da Amazônia S.A."
  },
  {
    cod: "BANCO_DO_NORDESTE",
    name: "Banco do Nordeste do Brasil S.A."
  },
  {
    cod: "BNDES",
    name: "Banco Nacional de Desenvolvimento Econômico e Social – BNDES"
  },
  {
    cod: "CREDICOAMO",
    name: "CREDICOAMO CREDITO RURAL COOPERATIVA"
  },
  {
    cod: "CREDIT",
    name: "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A"
  },
  {
    cod: "Banco_INBURSA",
    name: "Banco Inbursa S.A."
  },
  {
    cod: "STATE_STREET_BRASIL",
    name: "State Street Brasil S.A. – Banco Comercial"
  },
  {
    cod: "UBS_CORRETORA_CAMBIO",
    name: "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A."
  },
  {
    cod: "COOPERATIVA_DE_CREDITO_MUTUO_DOS_DESPACHANTES_DE_TRANSITO_DE_SANTA_CATARINA",
    name: "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI"
  },
  {
    cod: "BNY",
    name: "BNY Mellon Banco S.A."
  },
  {
    cod: "TRICURY",
    name: "Banco Tricury S.A."
  },
  {
    cod: "BANESTES",
    name: "BANESTES S.A. Banco do Estado do Espírito Santo"
  },
  {
    cod: "BANDEPE",
    name: "Banco BANDEPE S.A."
  },
  {
    cod: "ALFA",
    name: "Banco Alfa S.A."
  },
  {
    cod: "ITAU_CONSIGNADO",
    name: "Banco Itaú Consignado S.A."
  },
  {
    cod: "SANTANDER",
    name: "Banco Santander (Brasil) S.A."
  },
  {
    cod: "BRADESCO_BBI",
    name: "Banco Bradesco BBI S.A."
  },
  {
    cod: "BANCO_DO_ESTADO_DO_PARA",
    name: "Banco do Estado do Pará S.A."
  },
  {
    cod: "CARGILL",
    name: "Banco Cargill S.A."
  },
  {
    cod: "BANCO_DO_ESTADO_DO_RIO_GRANDE_DO_SUL",
    name: "Banco do Estado do Rio Grande do Sul S.A."
  },
  {
    cod: "BANCO_DO_ESTADO_DE_SERGIPE",
    name: "Banco do Estado de Sergipe S.A."
  },
  {
    cod: "CONFIDENCE",
    name: "Confidence Corretora de Câmbio S.A."
  },
  {
    cod: "HIPERCARD",
    name: "Hipercard Banco Múltiplo S.A."
  },
  {
    cod: "BRADESCARD",
    name: "Banco Bradescard S.A."
  },
  {
    cod: "GOLDMAN",
    name: "Goldman Sachs do Brasil Banco Múltiplo S.A."
  },
  {
    cod: "ANDBANK",
    name: "Banco Andbank (Brasil) S.A."
  },
  {
    cod: "MORGAN",
    name: "Banco Morgan Stanley S.A."
  },
  {
    cod: "CREFISA",
    name: "Banco Crefisa S.A."
  },
  {
    cod: "BRB",
    name: "BRB – Banco de Brasília S.A."
  },
  {
    cod: "J_SAFRA",
    name: "Banco J. Safra S.A."
  },
  {
    cod: "ABN_AMRO",
    name: "Banco ABN AMRO S.A."
  },
  {
    cod: "KDB",
    name: "Banco KDB S.A."
  },
  {
    cod: "INTER",
    name: "Banco Inter S.A."
  },
  {
    cod: "HAITONG",
    name: "Haitong Banco de Investimento do Brasil S.A."
  },
  {
    cod: "ORIGINAL_AGRONEGOCIO",
    name: "Banco Original do Agronegócio S.A."
  },
  {
    cod: "BT",
    name: "B&T CORRETORA DE CAMBIO LTDA."
  },
  {
    cod: "BANCOSEGURO",
    name: "BancoSeguro S.A."
  },
  {
    cod: "TOPAZIO",
    name: "Banco Topázio S.A."
  },
  {
    cod: "CHINA_BRASIL",
    name: "Banco da China Brasil S.A."
  },
  {
    cod: "UNIPRIME_NORTE_PARANA",
    name: "Uniprime Norte do Paraná – Coop de Economia e Crédito Mútuo dos Médicos"
  },
  {
    cod: "AILOS",
    name: "Cooperativa Central de Crédito – AILOS"
  },
  {
    cod: "CREDISAN",
    name: "CREDISAN COOPERATIVA DE CRÉDITO"
  },
  {
    cod: "CENTRAL_DE_COOPERATIVAS_DE_ECONOMIA_CREDITO_MUTUO_DO_ESTADO_DO_RIO_GRANDE",
    name: "CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S"
  },
  {
    cod: "BRICKELL",
    name: "Brickell S.A. Crédito"
  },
  {
    cod: "POLOCRED",
    name: "PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT"
  },
  {
    cod: "FINAXIS",
    name: "Banco Finaxis S.A."
  },
  {
    cod: "TRAVELEX",
    name: "Travelex Banco de Câmbio S.A."
  },
  {
    cod: "B3",
    name: "Banco B3 S.A."
  },
  {
    cod: "COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO",
    name: "Cooperativa Central de Crédito Noroeste Brasileiro Ltda."
  },
  {
    cod: "CREDIALIANCA",
    name: "Credialiança Cooperativa de Crédito Rural"
  },
  {
    cod: "UNIPRIME_CENTRAL",
    name: "UNIPRIME CENTRAL – CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA."
  },
  {
    cod: "PLANNER",
    name: "Planner Corretora de Valores S.A."
  },
  {
    cod: "RENASCENCA",
    name: "RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA"
  },
  {
    cod: "XP_INVESTIMENTOS",
    name: "XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A"
  },
  {
    cod: "CAIXA",
    name: "Caixa Econômica Federal"
  },
  {
    cod: "LECCA",
    name: "Lecca Crédito, Financiamento e Investimento S/A"
  },
  {
    cod: "BOCOM",
    name: "Banco BOCOM BBM S.A."
  },
  {
    cod: "PORTOCRED",
    name: "PORTOCRED S.A. – CREDITO, FINANCIAMENTO E INVESTIMENTO"
  },
  {
    cod: "OLIVEIRA",
    name: "OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A."
  },
  {
    cod: "MAGLIANO",
    name: "Magliano S.A. Corretora de Cambio e Valores Mobiliarios"
  },
  {
    cod: "CECOOP",
    name: "Central Cooperativa de Crédito no Estado do Espírito Santo – CECOOP"
  },
  {
    cod: "ADVANCED",
    name: "ADVANCED CORRETORA DE CÂMBIO LTDA"
  },
  {
    cod: "WESTERN",
    name: "Banco Western Union do Brasil S.A."
  },
  {
    cod: "RODOBENS",
    name: "Banco Rodobens S.A."
  },
  {
    cod: "AGIBANK",
    name: "Banco Agibank S.A."
  },
  {
    cod: "BRADESCO_BERJ",
    name: "Banco Bradesco BERJ S.A."
  },
  {
    cod: "WOORI_BANK",
    name: "Banco Woori Bank do Brasil S.A."
  },
  {
    cod: "PLURAL",
    name: "Plural S.A. – Banco Múltiplo"
  },
  {
    cod: "BR_PARTNERS",
    name: "BR Partners Banco de Investimento S.A."
  },
  {
    cod: "CODEPE",
    name: "Codepe Corretora de Valores e Câmbio S.A."
  },
  {
    cod: "MS_BANK",
    name: "MS Bank S.A. Banco de Câmbio"
  },
  {
    cod: "UBS_BRASIL",
    name: "UBS Brasil Banco de Investimento S.A."
  },
  {
    cod: "CARUANA",
    name: "CARUANA S.A. – SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO"
  },
  {
    cod: "TULLETT",
    name: "TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA"
  },
  {
    cod: "ICBC",
    name: "ICBC do Brasil Banco Múltiplo S.A."
  },
  {
    cod: "CONFEDERACAO_NACIONAL_DAS_COOPERATIVAS_CENTRAIS",
    name: "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E"
  },
  {
    cod: "BGC",
    name: "BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA"
  },
  {
    cod: "UNICRED",
    name: "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. – UNICRED DO BRASI"
  },
  {
    cod: "GET_MONEY",
    name: "Get Money Corretora de Câmbio S.A."
  },
  {
    cod: "INTESA",
    name: "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo"
  },
  {
    cod: "EASYNVEST",
    name: "Easynvest – Título Corretora de Valores SA"
  },
  {
    cod: "BROKER",
    name: "Broker Brasil Corretora de Câmbio Ltda."
  },
  {
    cod: "TREVISO",
    name: "Treviso Corretora de Câmbio S.A."
  },
  {
    cod: "BEXS",
    name: "BEXS Banco de Câmbio S.A."
  },
  {
    cod: "LEVYCAM",
    name: "LEVYCAM – CORRETORA DE CAMBIO E VALORES LTDA."
  },
  {
    cod: "GUITTA",
    name: "GUITTA CORRETORA DE CAMBIO LTDA."
  },
  {
    cod: "FACTA",
    name: "Facta Financeira S.A. – Crédito Financiamento e Investimento"
  },
  {
    cod: "ICAP",
    name: "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda."
  },
  {
    cod: "CASA_DO_CREDITO",
    name: "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor"
  },
  {
    cod: "COMMERZBANK",
    name: "Commerzbank Brasil S.A. – Banco Múltiplo"
  },
  {
    cod: "OLE",
    name: "Banco Olé Bonsucesso Consignado S.A."
  },
  {
    cod: "BRL_TRUST",
    name: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A."
  },
  {
    cod: "PERNAMBUCANAS",
    name: "PERNAMBUCANAS FINANCIADORA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO"
  },
  {
    cod: "GUIDE",
    name: "Guide Investimentos S.A. Corretora de Valores"
  },
  {
    cod: "CM_CAPITAL",
    name: "CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA"
  },
  {
    cod: "SOCRED",
    name: "SOCRED S.A. – SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P"
  },
  {
    cod: "ITAU",
    name: "Banco Itaú BBA S.A."
  },
  {
    cod: "ATIVA",
    name: "ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES"
  },
  {
    cod: "HS",
    name: "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS"
  },
  {
    cod: "SERVICOOP",
    name: "SERVICOOP – COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN"
  },
  {
    cod: "NOVA_FUTURA",
    name: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda."
  },
  {
    cod: "PARMETAL",
    name: "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA"
  },
  {
    cod: "FAIR",
    name: "FAIR CORRETORA DE CAMBIO S.A."
  },
  {
    cod: "STONE",
    name: "Stone Pagamentos S.A."
  },
  {
    cod: "BTG",
    name: "Banco BTG Pactual S.A."
  },
  {
    cod: "ORIGINAL",
    name: "Banco Original S.A."
  },
  {
    cod: "ARBI",
    name: "Banco Arbi S.A."
  },
  {
    cod: "JOHN",
    name: "Banco John Deere S.A."
  },
  {
    cod: "BS2",
    name: "Banco BS2 S.A."
  },
  {
    cod: "CREDIT_AGRICOLE",
    name: "Banco Credit Agricole Brasil S.A."
  },
  {
    cod: "FIBRA",
    name: "Banco Fibra S.A."
  },
  {
    cod: "CIFRA",
    name: "Banco Cifra S.A."
  },
  {
    cod: "NEXT",
    name: "Next"
  },
  {
    cod: "BRADESCO",
    name: "Banco Bradesco S.A."
  },
  {
    cod: "CLASSICO",
    name: "Banco Clássico S.A."
  },
  {
    cod: "MAXIMA",
    name: "Banco Máxima S.A."
  },
  {
    cod: "ABC",
    name: "Banco ABC Brasil S.A."
  },
  {
    cod: "INVESTCRED",
    name: "Banco Investcred Unibanco S.A."
  },
  {
    cod: "BCV",
    name: "BCV – Banco de Crédito e Varejo S.A."
  },
  {
    cod: "BEXS_CORRETORA",
    name: "Bexs Corretora de Câmbio S/A"
  },
  {
    cod: "PARANA",
    name: "Paraná Banco S.A."
  },
  {
    cod: "MONEYCORP",
    name: "MONEYCORP BANCO DE CÂMBIO S.A."
  },
  {
    cod: "NUBANK",
    name: "NU Pagamentos S.A. – Nubank"
  },
  {
    cod: "FATOR",
    name: "Banco Fator S.A."
  },
  {
    cod: "CEDULA",
    name: "Banco Cédula S.A."
  },
  {
    cod: "BARI_COMPANHIA",
    name: "BARI COMPANHIA HIPOTECÁRIA"
  },
  {
    cod: "CELCOIN",
    name: "CELCOIN INSTITUICAO DE PAGAMENTO S.A."
  },
  {
    cod: "HSBC",
    name: "HSBC Brasil S.A. – Banco de Investimento"
  },
  {
    cod: "SAGITUR",
    name: "Sagitur Corretora de Câmbio Ltda."
  },
  {
    cod: "IB",
    name: "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A."
  },
  {
    cod: "AGK",
    name: "AGK CORRETORA DE CAMBIO S.A."
  },
  {
    cod: "SULCREDI",
    name: "Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel"
  },
  {
    cod: "MONEY",
    name: "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT"
  },
  {
    cod: "SENFF",
    name: "Senff S.A. – Crédito, Financiamento e Investimento"
  },
  {
    cod: "GENIAL",
    name: "Genial Investimentos Corretora de Valores Mobiliários S.A."
  },
  {
    cod: "COOPERATIVA_DE_CREDITO_RURAL_DE_PRIMAVERA_DO_LESTE",
    name: "COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE"
  },
  {
    cod: "AVISTA",
    name: "Avista S.A. Crédito, Financiamento e Investimento"
  },
  {
    cod: "COOPAVEL",
    name: "Cooperativa de Crédito Rural Coopavel"
  },
  {
    cod: "RB",
    name: "RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA"
  },
  {
    cod: "FRENTE_CORRETORA_DE_CAMBIO",
    name: "Frente Corretora de Câmbio Ltda."
  },
  {
    cod: "COOPERATIVA_DE_CREDITO_RURAL_DE_OURO",
    name: "COOPERATIVA DE CRÉDITO RURAL DE OURO SULCREDI/OURO"
  },
  {
    cod: "CAROL",
    name: "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
  },
  {
    cod: "DECYSEO",
    name: "DECYSEO CORRETORA DE CAMBIO LTDA."
  },
  {
    cod: "PAGSEGURO",
    name: "Pagseguro Internet S.A. – PagBank"
  },
  {
    cod: "BS2_TITULOS_MOBILIARIOS",
    name: "BS2 Distribuidora de Títulos e Valores Mobiliários S.A."
  },
  {
    cod: "LASTRO",
    name: "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda."
  },
  {
    cod: "VISION",
    name: "VISION S.A. CORRETORA DE CAMBIO"
  },
  {
    cod: "VIPS",
    name: "Vip’s Corretora de Câmbio Ltda."
  },
  {
    cod: "SOROCRED",
    name: "SOROCRED CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A."
  },
  {
    cod: "LA_NACION",
    name: "Banco de La Nacion Argentina"
  },
  {
    cod: "BPP",
    name: "BPP Instituição de Pagamento S.A."
  },
  {
    cod: "PORTOPAR",
    name: "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
  },
  {
    cod: "TERRA",
    name: "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda."
  },
  {
    cod: "CAMBIONET",
    name: "CAMBIONET CORRETORA DE CÂMBIO LTDA."
  },
  {
    cod: "VORTX",
    name: "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
  },
  {
    cod: "PI",
    name: "PI Distribuidora de Títulos e Valores Mobiliários S.A."
  },
  {
    cod: "BMG",
    name: "Banco BMG S.A."
  },
  {
    cod: "OM",
    name: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA"
  },
  {
    cod: "CHINA",
    name: "China Construction Bank (Brasil) Banco Múltiplo S.A."
  },
  {
    cod: "CREFAZ",
    name: "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT"
  },
  {
    cod: "CREDILUZ",
    name: "Cooperativa de Crédito Rural de Abelardo Luz – Sulcredi/Crediluz"
  },
  {
    cod: "MERCADOPAGO",
    name: "MERCADOPAGO.COM REPRESENTACOES LTDA."
  },
  {
    cod: "ORAMA",
    name: "Órama Distribuidora de Títulos e Valores Mobiliários S.A."
  },
  {
    cod: "PARATI",
    name: "PARATI – CREDITO, FINANCIAMENTO E INVESTIMENTO S.A."
  },
  {
    cod: "QI",
    name: "QI Sociedade de Crédito Direto S.A."
  },
  {
    cod: "BARI_FINANCIAMENTOS",
    name: "Banco Bari de Investimentos e Financiamentos S/A"
  },
  {
    cod: "FRAM",
    name: "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A."
  },
  {
    cod: "ACESSO_SOLUCOES",
    name: "Acesso Soluções de Pagamento S.A."
  },
  {
    cod: "DIGIO",
    name: "Banco Digio S.A."
  },
  {
    cod: "C6",
    name: "Banco C6 S.A – C6 Bank"
  },
  {
    cod: "SUPER",
    name: "Super Pagamentos e Administração de Meios Eletrônicos S.A."
  },
  {
    cod: "ITAU_UNIBANCO",
    name: "Itaú Unibanco S.A."
  },
  {
    cod: "CREDITAS",
    name: "Creditas Sociedade de Crédito Direto S.A."
  },
  {
    cod: "FFA",
    name: "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA."
  },
  {
    cod: "XP",
    name: "Banco XP S.A."
  },
  {
    cod: "AMAGGI",
    name: "AMAGGI S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO"
  },
  {
    cod: "TORO",
    name: "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA"
  },
  {
    cod: "NECTON",
    name: "NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES"
  },
  {
    cod: "OTIMO",
    name: "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A."
  },
  {
    cod: "GERENCIANET",
    name: "GERENCIANET PAGAMENTOS DO BRASIL LTDA"
  },
  {
    cod: "SOLIDUS",
    name: "SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS"
  },
  {
    cod: "SOCIETE",
    name: "Banco Société Générale Brasil S.A."
  },
  {
    cod: "VITREO",
    name: "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A."
  },
  {
    cod: "CARREFOUR",
    name: "Banco Carrefour"
  },
  {
    cod: "MIZUHO",
    name: "Banco Mizuho do Brasil S.A."
  },
  {
    cod: "UP",
    name: "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A."
  },
  {
    cod: "J_P_MORGAN",
    name: "Banco J. P. Morgan S.A."
  },
  {
    cod: "PICPAY",
    name: "PicPay"
  },
  {
    cod: "MERCANTIL",
    name: "Banco Mercantil do Brasil S.A."
  },
  {
    cod: "BRADESCO_FINANCIAMENTO",
    name: "Banco Bradesco Financiamentos S.A."
  },
  {
    cod: "KIRTON",
    name: "Kirton Bank S.A. – Banco Múltiplo"
  },
  {
    cod: "CAPITAL",
    name: "Banco Capital S.A."
  },
  {
    cod: "SAFRA",
    name: "Banco Safra S.A."
  },
  {
    cod: "MUFG",
    name: "Banco MUFG Brasil S.A."
  },
  {
    cod: "SUMITOMO",
    name: "Banco Sumitomo Mitsui Brasileiro S.A."
  },
  {
    cod: "CAIXA_GERAL",
    name: "Banco Caixa Geral – Brasil S.A."
  },
  {
    cod: "CITIBANK_NA",
    name: "Citibank N.A."
  },
  {
    cod: "ITAUBANK",
    name: "Banco ItauBank S.A"
  },
  {
    cod: "DEUTSCHE",
    name: "Deutsche Bank S.A. – Banco Alemão"
  },
  {
    cod: "JPMORGAN",
    name: "JPMorgan Chase Bank"
  },
  {
    cod: "ING",
    name: "ING Bank N.V."
  },
  {
    cod: "LA_PROVINCIA",
    name: "Banco de La Provincia de Buenos Aires"
  },
  {
    cod: "CREDIT_SUISSE",
    name: "Banco Credit Suisse (Brasil) S.A."
  },
  {
    cod: "SENSO",
    name: "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A"
  },
  {
    cod: "LUSO",
    name: "Banco Luso Brasileiro S.A."
  },
  {
    cod: "INDUSTRIAL",
    name: "Banco Industrial do Brasil S.A."
  },
  {
    cod: "VR",
    name: "Banco VR S.A."
  },
  {
    cod: "PAULISTA",
    name: "Banco Paulista S.A."
  },
  {
    cod: "GUANABARA",
    name: "Banco Guanabara S.A."
  },
  {
    cod: "OMNI",
    name: "Omni Banco S.A."
  },
  {
    cod: "PAN",
    name: "Banco PAN S.A."
  },
  {
    cod: "FICSA",
    name: "Banco Ficsa S.A."
  },
  {
    cod: "SMARTBANK",
    name: "Banco Smartbank S.A."
  },
  {
    cod: "RENDIMENTO",
    name: "Banco Rendimento S.A."
  },
  {
    cod: "TRIANGULO",
    name: "Banco Triângulo S.A."
  },
  {
    cod: "SOFISA",
    name: "Banco Sofisa S.A."
  },
  {
    cod: "PINE",
    name: "Banco Pine S.A."
  },
  {
    cod: "ITAU_UNIBANCO_HOLDING",
    name: "Itaú Unibanco Holding S.A."
  },
  {
    cod: "INDUSVAL",
    name: "Banco Indusval S.A."
  },
  {
    cod: "RENNER",
    name: "Banco A.J.Renner S.A."
  },
  {
    cod: "VOTORANTIM",
    name: "Banco Votorantim S.A."
  },
  {
    cod: "DAYCOVAL",
    name: "Banco Daycoval S.A."
  },
  {
    cod: "OURINVEST",
    name: "Banco Ourinvest S.A."
  },
  {
    cod: "CETELEM",
    name: "Banco Cetelem S.A."
  },
  {
    cod: "RIBEIRAO_PRETO",
    name: "Banco Ribeirão Preto S.A."
  },
  {
    cod: "SEMEAR",
    name: "Banco Semear S.A."
  },
  {
    cod: "CITIBANK",
    name: "Banco Citibank S.A."
  },
  {
    cod: "MODAL",
    name: "Banco Modal S.A."
  },
  {
    cod: "RABOBANK",
    name: "Banco Rabobank International Brasil S.A."
  },
  {
    cod: "SICREDI",
    name: "Banco Cooperativo Sicredi S.A."
  },
  {
    cod: "SCOTIABANK",
    name: "Scotiabank Brasil S.A. Banco Múltiplo"
  },
  {
    cod: "BNP",
    name: "Banco BNP Paribas Brasil S.A."
  },
  {
    cod: "CONTINENTAL",
    name: "Novo Banco Continental S.A. – Banco Múltiplo"
  },
  {
    cod: "SISTEMA",
    name: "Banco Sistema S.A."
  },
  {
    cod: "MERRILL",
    name: "Bank of America Merrill Lynch Banco Múltiplo S.A."
  },
  {
    cod: "BANCOOB",
    name: "Banco Cooperativo do Brasil S.A. – BANCOOB"
  },
  {
    cod: "KEB",
    name: "Banco KEB HANA do Brasil S.A."
  },
];

export const ESTADOS_JSON = [
  {
    nome: "Acre",
    sigla: "AC",
  },
  {
    nome: "Alagoas",
    sigla: "AL",
  },
  {
    nome: "Amapá",
    sigla: "AP",
  },
  {
    nome: "Amazonas",
    sigla: "AM",
  },
  {
    nome: "Bahia",
    sigla: "BA",
  },
  {
    nome: "Ceará",
    sigla: "CE",
  },
  {
    nome: "Distrito Federal",
    sigla: "DF",
  },
  {
    nome: "Espírito Santo",
    sigla: "ES",
  },
  {
    nome: "Goiás",
    sigla: "GO",
  },
  {
    nome: "Maranhão",
    sigla: "MA",
  },
  {
    nome: "Mato Grosso",
    sigla: "MT",
  },
  {
    nome: "Mato Grosso do Sul",
    sigla: "MS",
  },
  {
    nome: "Minas Gerais",
    sigla: "MG",
  },
  {
    nome: "Pará",
    sigla: "PA",
  },
  {
    nome: "Paraíba",
    sigla: "PB",
  },
  {
    nome: "Paraná",
    sigla: "PR",
  },
  {
    nome: "Pernambuco",
    sigla: "PE",
  },
  {
    nome: "Piauí",
    sigla: "PI",
  },
  {
    nome: "Rio de Janeiro",
    sigla: "RJ",
  },
  {
    nome: "Rio Grande do Norte",
    sigla: "RN",
  },
  {
    nome: "Rio Grande do Sul",
    sigla: "RS",
  },
  {
    nome: "Rondônia",
    sigla: "RO",
  },
  {
    nome: "Roraima",
    sigla: "RR",
  },
  {
    nome: "Santa Catarina",
    sigla: "SC",
  },
  {
    nome: "São Paulo",
    sigla: "SP",
  },
  {
    nome: "Sergipe",
    sigla: "SE",
  },
  {
    nome: "Tocantins",
    sigla: "TO",
  },
];

export const MESSAGE_VALIDATION_INPUT = {
  REQUERID_FIELD: "Campo Obrigatório!",
  INVALID_EMAIL_FIELD: "Email Inválido!",
  INVALID_DATE: "Formato de data inválida!",
};

export const DOMINIO_STATUS_COLABORADOR = Object.freeze({
  ATIVA: { cod: "ATIVA", name: "Ativo" },
  INATIVA: { cod: "INATIVA", name: "Inativo" },
});


export const DOMINIO_STATUS_ATIVO_INATIVO = Object.freeze({
  ATIVO: { cod: "ATIVO", name: "Ativo" },
  INATIVO: { cod: "INATIVO", name: "Inativo" },
});

export const DOMINIO_STATUS_EMPRESA = Object.freeze({
  ATIVA: { cod: "ATIVA", name: "Ativa" },
  SUSPENSA: { cod: "SUSPENSA", name: "Suspensa" },
  INATIVA: { cod: "INATIVA", name: "Inativa" },
});

export const DOMINIO_TIPO_PLANO = Object.freeze({
  BASIC: { cod: "BASIC", name: "Essencial" },
  STANDARD: { cod: "STANDARD", name: "Avançado" },
});

export const DOMINIO_TIPO_FATURAMENTO = Object.freeze({
  SIMPLES_NACIONAL: {
    cod: 0,
    desc: "Simples Nacional",
    name: "SIMPLES_NACIONAL",
  },
  LUCRO_REAL_PRESUMIDO: {
    cod: 1,
    desc: "Lucro Real/Presumido",
    name: "LUCRO_REAL_PRESUMIDO",
  },
});

export const DOMINIO_FREQUENCIA_COBRANCA = Object.freeze({
  MENSAL: { cod: "MENSAL", name: "Mensal" },
  TRIMESTRAL: { cod: "TRIMESTRAL", name: "Trimestral" },
  SEMESTRAL: { cod: "SEMESTRAL", name: "Semestral" },
  ANUAL: { cod: "ANUAL", name: "Anual" },
});

export const DOMINIO_ORIGEM_LEAD = [
  "Google ADS",
  "Busca Orgânica",
  "Tráfego Direto",
  "Eventos/Feiras",
  "Bing",
  "Facebook",
  "Instagram",
  "Indicação Cliente",
  "Indicação Outros",
  "SDR",
  "Comercial",
  "Trial",
  "Email Marketing",
  "Outros",
];

export const DOMINIO_SIM_NAO = Object.freeze({
  SIM: { cod: 1, desc: "Sim", name: "SIM" },
  NAO: { cod: 0, desc: "Não", name: "NAO" },
});

export const AVERBADORA = Object.freeze({
  ZETRA: { cod: 1, desc: "ZetraSoft", name: "ZETRA" },
  SAFECONSIG: { cod: 2, desc: "Safe Consig", name: "SAFECONSIG" },
  FACIL: { cod: 3, desc: "Fácil", name: "FACIL" },
  CONSIGLOG: { cod: 4, desc: "ConsigLog", name: "CONSIGLOG" },
  NEOCONSIG: { cod: 5, desc: "NeoConsig", name: "NEOCONSIG" },
  INFOCONSIG: { cod: 6, desc: "Info Consig", name: "INFOCONSIG" },
  FENIX: { cod: 7, desc: "Fenix", name: "FENIX" },
  RF1: { cod: 8, desc: "RF1", name: "RF1" },
  LINKCONSIG: { cod: 9, desc: "Link Consig", name: "LINKCONSIG" },
  SICON: { cod: 10, desc: "Sicon", name: "SICON" },
  DIGITAL_CONSIG: { cod: 11, desc: "DigitalConsig", name: "DIGITAL_CONSIG" },
  MONTREAL_CONSIGO: { cod: 12, desc: "Montreal Consigo", name: "MONTREAL_CONSIGO" },
  KONEXIA: { cod: 13, desc: "Konexia", name: "KONEXIA" },
  INTERNO: { cod: 14, desc: "Interno", name: "INTERNO" },
  CONSIGNET: { cod: 15, desc: "Consignet", name: "CONSIGNET" },
});

export const DominioStatusDescontoParcela = Object.freeze({
  SUCESSO: { cod: 1, desc: "Sucesso", name: "SUCESSO" },
  FALHA: { cod: 2, desc: "Falha", name: "FALHA" },
  PARCIAL: { cod: 3, desc: "Parcial", name: "PARCIAL" },
  NAO_PROCESSADA: { cod: 4, desc: "Não Processada", name: "NAO_PROCESSADA" },
});

export const DOMINIO_DIAS_GERAR_FATURA = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
];

export const DOMINIO_MODO_COBRANCA = Object.freeze({
  GERADO_MES_MES: { cod: 1, desc: "Gerado Mês a Mês", name: "GERADO_MES_MES" },
  RECORENTE_CARTAO_CREDITO: {
    cod: 2,
    desc: "Recorrente no Cartão de Crédito",
    name: "RECORENTE_CARTAO_CREDITO",
  },
});

export const DOMINIO_SEVERITY_MENSAGEM = Object.freeze({
  ERROR: { cod: "error", desc: "Erro", name: "error" },
  WARNING: { cod: "warning", desc: "Atenção", name: "warning" },
  INFO: { cod: "info", desc: "Informativa", name: "info" },
  SUCCESS: { cod: "success", desc: "Sucesso", name: "success" },
});

export const DOMINIO_TIPO_MOTIVO_AJUSTE = Object.freeze({
  ABONO: { cod: 1, desc: "Abono", name: "ABONO" },
  AJUSTE_HORARIO: { cod: 2, desc: "Ajuste de horário", name: "AJUSTE_HORARIO" },
  BANCO_HORAS: {
    cod: 3,
    desc: "Considerar no banco de horas",
    name: "BANCO_HORAS",
  },
  DESCANSO_REMUNERADO: {
    cod: 4,
    desc: "Descanso semanal remunerado",
    name: "DESCANSO_REMUNERADO",
  },
  FALTA_DESCONTO: {
    cod: 5,
    desc: "Falta com desconto",
    name: "FALTA_DESCONTO",
  },
  IGNORAR_BANCO: {
    cod: 6,
    desc: "Abater do Banco de Horas sem Considerar Falta",
    name: "IGNORAR_BANCO",
  },
  SUSPENSAO: { cod: 7, desc: "Suspensão", name: "SUSPENSAO" },
});

export const DOMINIO_TIPO_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: "ADMINISTRADORA" },
  BACKOFFICE: { cod: 2, desc: "Back Office", name: "BACKOFFICE" },
  CORBAN: { cod: 3, desc: "Corban", name: "CORBAN" },
  GERENTE_PROMOTORA: { cod: 5, desc: "Gerente Promotora", name: "GERENTE_PROMOTORA" },
});

export const DOMINIO_TIPO_NOTIFICACAO = Object.freeze({
  EMAIL: { cod: 1, desc: "E-mail", name: "EMAIL" },
  SISTEMA: { cod: 2, desc: "Sistema", name: "SISTEMA" },
});

export const DOMINIO_ATIVO_INATIVO = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  INATIVO: { cod: 0, desc: "Inativo", name: "INATIVO" },
});

export const DOMINIO_ATIVO_INATIVO_COLABORADOR = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  INATIVO: { cod: 0, desc: "Desligado", name: "INATIVO" },
});

export const DOMINIO_PAPEL_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: "ADMINISTRADORA" },
  GESTOR: { cod: 2, desc: "Gestor", name: "GESTOR" },
  SUPORTE: { cod: 3, desc: "Suporte", name: "SUPORTE" },
  CORBAN: { cod: 4, desc: "Corban", name: "CORBAN" },
  GERENTE_PROMOTORA: { cod: 5, desc: "Gerente Promotora", name: "GERENTE_PROMOTORA" },
});

export const DOMINIO_PUBLICO_ALVO_MENSAGEM = Object.freeze({
  INDIVIDUAL: { cod: 1, desc: "Individual", name: "INDIVIDUAL" },
  TODOS: { cod: 2, desc: "Todos", name: "TODOS" },
  BACKOFFICE: { cod: 5, desc: "Back Office", name: "BACKOFFICE" },
  ADMINISTRADORES: { cod: 3, desc: "Administradores", name: "ADMINISTRADORES" },
  CORBAN: { cod: 4, desc: "Corban", name: "CORBAN" },
  GERENTE_PROMOTORA: { cod: 5, desc: "Gerente Promotora", name: "GERENTE_PROMOTORA" },
});

export const DOMINIO_MODELO_REP = Object.freeze({
  HENRY_PRISMA_HEXA_ADVANCED: {
    cod: 1,
    desc: "Henry - Prisma/Hexa Advanced",
    name: "HENRY_PRISMA_HEXA_ADVANCED",
  },
  HENRY_PRISMA_SUPER_FACIL_ADVANCED: {
    cod: 2,
    desc: "Henry - Prisma Super Fácil Advanced",
    name: "HENRY_PRISMA_SUPER_FACIL_ADVANCED",
  },
  INNER_REP_BIO_2I: {
    cod: 3,
    desc: "INNER REP BIO 2i",
    name: "INNER_REP_BIO_2I",
  },
  RWTECH: { cod: 4, desc: "RwTech", name: "RWTECH" },
  INNER_REP_PLUS_BIO_PROX: {
    cod: 5,
    desc: "INNER REP PLUS BIO PROX",
    name: "INNER_REP_PLUS_BIO_PROX",
  },
  KURUMIM_REP_BIO_NT_BR: {
    cod: 6,
    desc: "KURUMIM REP BIO NT BR",
    name: "KURUMIM_REP_BIO_NT_BR",
  },
  PRINT_POINT_III: { cod: 7, desc: "Print Point III", name: "PRINT_POINT_III" },
  CONTROL_ID: { cod: 9, desc: "Control ID", name: "CONTROL_ID" },
});



export const DOMINIO_STATUS_CONTRATO = Object.freeze({
  CCB_EMITIDA: { cod: 1, desc: "CCB Emitida", name: "CCB_EMITIDA" },
  LINK_ASSINATURA: { cod: 2, desc: "Link Assinatura", name: "LINK_ASSINATURA" },
  ASSINATURA_CONCLUIDA: { cod: 3, desc: "Assinatura Concluida", name: "ASSINATURA_CONCLUIDA" },
  ANALISE_FORMALIZACAO: { cod: 4, desc: "Analise Formalização", name: "ANALISE_FORMALIZACAO" },
  REANALISE_FORMALIZACAO: { cod: 14, desc: "Reanalise Formalização", name: "REANALISE_FORMALIZACAO" },
  AGUARDANDO_PAGAMENTO: { cod: 5, desc: "Aguardando Pagamento", name: "AGUARDANDO_PAGAMENTO" },
  FINALIZADA: { cod: 6, desc: "Finalizada", name: "FINALIZADA" },
  LIBERADA: { cod: 7, desc: "Liberada", name: "LIBERADA" },
  CEDIDA: { cod: 8, desc: "Cedida", name: "CEDIDA" },
  PAGA: { cod: 9, desc: "Paga", name: "PAGA" },
  PENDENTE_PAGAMENTO: { cod: 10, desc: "Pendente de Pagamento", name: "PENDENTE_PAGAMENTO" },
  CANCELADA: { cod: 11, desc: "Cancelada", name: "CANCELADA" },
  PENDENCIADA: { cod: 13, desc: "Pendenciada", name: "PENDENCIADA" },
});

export const DOMINIO_TIPO_ARQUIVO = Object.freeze({
  CARTEIRA_DE_TRABALHO: { cod: 15, desc: "Carteira de Trabalho", name: "CARTEIRA_DE_TRABALHO" },
  CCB: { cod: 0, desc: "CCB", name: "CCB" },  
  CNH: { cod: 9, desc: "CNH Frente", name: "CNH" },
  CNH_VERSO: { cod: 12, desc: "CNH Verso", name: "CNH_VERSO" }, 
  COMPROVANTE_AVERBACAO: { cod: 1, desc: "Comprovante de Averbação", name: "COMPROVANTE_AVERBACAO" },  
  COMPROVANTE_RESIDENCIA: { cod: 2, desc: "Comprovante de Residência", name: "COMPROVANTE_RESIDENCIA" },  
  CONSULTA_MARGEM: { cod: 13, desc: "Consulta de Margem", name: "CONSULTA_MARGEM" },  
  CPF: { cod: 4, desc: "CPF", name: "CPF" },  
  EXTRATO_BANCARIO: { cod: 6, desc: "Extrato Bancário", name: "EXTRATO_BANCARIO" },  
  HOLERITE: { cod: 3, desc: "Holerite/Contra-Cheque", name: "HOLERITE" },  
  INFORME_DE_RENDIMENTOS: { cod: 7, desc: "Informe de Rendimentos", name: "INFORME_DE_RENDIMENTOS" },  
  OUTROS: { cod: 8, desc: "Outros", name: "OUTROS" },  
  PASSPORTE: { cod: 14, desc: "Passaporte", name: "PASSPORTE" },
  RG: { cod: 5, desc: "RG Frente", name: "RG" },
  RG_VERSO: { cod: 11, desc: "RG Verso", name: "RG_VERSO" },
  SELFIE: { cod: 10, desc: "Selfie", name: "SELFIE" },
});

export const DOMINIO_TIPO_MOTIVO = Object.freeze({
  AMBOS: { cod: 1, desc: "Ambos", name: "AMBOS" },
  CANCELAMENTO: { cod: 2, desc: "Cancelamento", name: "CANCELAMENTO" },
  PENDENCIAMENTO: { cod: 3, desc: "Pendenciamento", name: "PENDENCIAMENTO" }
});

export const DOMINIO_PERIODO_EXPERIENCIA = Object.freeze({
  P1X15DIAS: {
    cod: 1,
    desc: "1 x 15 dias",
    periodo: 1,
    dias: 15,
    name: "P1X15DIAS",
  },
  P1x30DIAS: {
    cod: 2,
    desc: "1 x 30 dias",
    periodo: 1,
    dias: 30,
    name: "P1x30DIAS",
  },
  P1x45DIAS: {
    cod: 3,
    desc: "1 x 45 dias",
    periodo: 1,
    dias: 45,
    name: "P1x45DIAS",
  },
  P1x60DIAS: {
    cod: 4,
    desc: "1 x 60 dias",
    periodo: 1,
    dias: 60,
    name: "P1x60DIAS",
  },
  P1x90DIAS: {
    cod: 5,
    desc: "1 x 90 dias",
    periodo: 1,
    dias: 90,
    name: "P1x90DIAS",
  },
  P2x15DIAS: {
    cod: 6,
    desc: "2 x 15 dias",
    periodo: 2,
    dias: 15,
    name: "P2x15DIAS",
  },
  P2X30DIAS: {
    cod: 7,
    desc: "2 x 30 dias",
    periodo: 2,
    dias: 30,
    name: "P2X30DIAS",
  },
  P2X45DIAS: {
    cod: 8,
    desc: "2 x 45 dias",
    periodo: 2,
    dias: 45,
    name: "P2X45DIAS",
  },
  CUSTOMIZA: {
    cod: 9,
    desc: "Customizado",
    periodo: 2,
    dias: 90,
    name: "CUSTOMIZA",
  },
});

export const DOMINIO_TIPO_TURNO = Object.freeze({
  SEMANAL: { cod: 1, desc: "Semanal", name: "SEMANAL" },
  INTERMITENTE: { cod: 6, desc: "Intermitente", name: "INTERMITENTE" },
  H12H36: { cod: 2, desc: "12Hx36H", name: "H12H36" },
  D1D3: { cod: 3, desc: "1Dx3D", name: "D1D3" },
  D4D3: { cod: 9, desc: "4Dx3D", name: "D4D3" },
  D5D1: { cod: 4, desc: "5Dx1D", name: "D5D1" },
  D6D2: { cod: 5, desc: "6Dx2D", name: "D6D2" },
  D6D3: { cod: 8, desc: "6Dx3D", name: "D6D3" },
  D7D1: { cod: 7, desc: "7Dx1D", name: "D7D1" },
  D2D2: { cod: 10, desc: "2Dx2D", name: "D2D2" },
  D3D2D2D3: { cod: 11, desc: "3Dx2D - 2Dx3D", name: "D3D2D2D3" },
  D4D4D3D3: { cod: 12, desc: "4Dx4D - 3Dx3D", name: "D4D4D3D3" },
  D3D2D2D3D2D2: { cod: 13, desc: "3Dx2D - 2Dx3D - 2Dx2D", name: "D3D2D2D3D2D2" },
  D3D3D2D2: { cod: 14, desc: "3Dx3D - 2Dx2D", name: "D3D3D2D2" },
});

export const DOMINIO_STATUS_MUTUARIO = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  DESLIGADO: { cod: 2, desc: "Desligado", name: "DESLIGADO" },
});

export const DOMINIO_FUSO_HORARIO = Object.freeze({
  UTC_DOZE: { cod: 12, desc: "12", longDesc: "(GMT 12:00)", name: "UTC_DOZE" },
  UTC_ONZE: { cod: 11, desc: "11", longDesc: "(GMT 11:00)", name: "UTC_ONZE" },
  UTC_DEZ: { cod: 10, desc: "10", longDesc: "(GMT 10:00)", name: "UTC_DEZ" },
  UTC_NOVE: { cod: 9, desc: "9", longDesc: "(GMT 09:00)", name: "UTC_NOVE" },
  UTC_OITO: { cod: 8, desc: "8", longDesc: "(GMT 08:00)", name: "UTC_OITO" },
  UTC_SETE: { cod: 7, desc: "7", longDesc: "(GMT 07:00)", name: "UTC_SETE" },
  UTC_SEIS: { cod: 6, desc: "6", longDesc: "(GMT 06:00)", name: "UTC_SEIS" },
  UTC_CINCO: { cod: 5, desc: "5", longDesc: "(GMT 05:00)", name: "UTC_CINCO" },
  UTC_QUATRO: {
    cod: 4,
    desc: "4",
    longDesc: "(GMT 04:00)",
    name: "UTC_QUATRO",
  },
  UTC_TRES: { cod: 3, desc: "3", longDesc: "(GMT 03:00)", name: "UTC_TRES" },
  UTC_DOIS: { cod: 2, desc: "2", longDesc: "(GMT 02:00)", name: "UTC_DOIS" },
  UTC_UM: { cod: 1, desc: "1", longDesc: "(GMT 01:00)", name: "UTC_UM" },
  UTC_ZERO: { cod: 0, desc: "0", longDesc: "(GMT 00:00)", name: "UTC_ZERO" },
  UTC_MENOS_UM: {
    cod: -1,
    desc: "-1",
    longDesc: "(GMT -01:00)",
    name: "UTC_MENOS_UM",
  },
  UTC_MENOS_DOIS: {
    cod: -2,
    desc: "-2",
    longDesc: "(GMT -02:00) Horário de Fernando de Noronha",
    name: "UTC_MENOS_DOIS",
  },
  UTC_MENOS_TRES: {
    cod: -3,
    desc: "-3",
    longDesc: "(GMT -03:00) Horário de Brasília",
    name: "UTC_MENOS_TRES",
  },
  UTC_MENOS_QUATRO: {
    cod: -4,
    desc: "-4",
    longDesc: "(GMT -04:00) Horário da Amazônia",
    name: "UTC_MENOS_QUATRO",
  },
  UTC_MENOS_CINCO: {
    cod: -5,
    desc: "-5",
    longDesc: "(GMT -05:00) Horário do Acre",
    name: "UTC_MENOS_CINCO",
  },
  UTC_MENOS_SEIS: {
    cod: -6,
    desc: "-6",
    longDesc: "(GMT -06:00)",
    name: "UTC_MENOS_SEIS",
  },
  UTC_MENOS_SETE: {
    cod: -7,
    desc: "-7",
    longDesc: "(GMT -07:00)",
    name: "UTC_MENOS_SETE",
  },
  UTC_MENOS_OITO: {
    cod: -8,
    desc: "-8",
    longDesc: "(GMT -08:00)",
    name: "UTC_MENOS_OITO",
  },
  UTC_MENOS_NOVE: {
    cod: -9,
    desc: "-9",
    longDesc: "(GMT -09:00)",
    name: "UTC_MENOS_NOVE",
  },
  UTC_MENOS_DEZ: {
    cod: -10,
    desc: "-10",
    longDesc: "(GMT -10:00)",
    name: "UTC_MENOS_DEZ",
  },
  UTC_MENOS_ONZE: {
    cod: -11,
    desc: "-11",
    longDesc: "(GMT -11:00)",
    name: "UTC_MENOS_ONZE",
  },
  UTC_MENOS_DOZE: {
    cod: -12,
    desc: "-12",
    longDesc: "(GMT -12:00)",
    name: "UTC_MENOS_DOZE",
  },
});

export const DOMINIO_TIPO_PERIODO = Object.freeze({
  ENTRADA: { cod: 1, desc: "Entrada", name: "ENTRADA" },
  SAIDA: { cod: 2, desc: "Saída", name: "SAIDA" },
});

export const DOMINIO_TIPO_OPERACAO_FOLHA = Object.freeze({
  TOTAL_HORAS_TRABALHADAS: {
    cod: 0,
    desc: "Total de horas trabalhadas",
    name: "TOTAL_HORAS_TRABALHADAS",
  },
  TOTAL_DIAS_ABONO: {
    cod: 3,
    desc: "Total de dias com Exceção/Abono",
    name: "TOTAL_DIAS_ABONO",
  },
  TOTAL_DIAS_FALTA: {
    cod: 4,
    desc: "Total de dias com falta",
    name: "TOTAL_DIAS_FALTA",
  },
  TOTAL_DIAS_FERIADOS_TRABALHADO: {
    cod: 5,
    desc: "Total de feriados trabalhados (dias)",
    name: "TOTAL_DIAS_FERIADOS_TRABALHADO",
  },
  TOTAL_HORAS_FERIADOS_TRABALHADO: {
    cod: 6,
    desc: "Total de feriados trabalhados (horas)",
    name: "TOTAL_HORAS_FERIADOS_TRABALHADO",
  },
  TOTAL_HORAS_FALTAS: {
    cod: 7,
    desc: "Total de horas em faltas",
    name: "TOTAL_HORAS_FALTAS",
  },
  TOTAL_HORAS_NOTURNAS: {
    cod: 8,
    desc: "Total de horas noturnas",
    name: "TOTAL_HORAS_NOTURNAS",
  },
  TOTAL_HORAS_EXTRAS: {
    cod: 9,
    desc: "Total de horas extras",
    name: "TOTAL_HORAS_EXTRAS",
  },
  TOTAL_HORAS_EXTRAS_1: {
    cod: 10,
    desc: "Total de horas extras 1",
    name: "TOTAL_HORAS_EXTRAS_1",
  },
  TOTAL_HORAS_EXTRAS_2: {
    cod: 11,
    desc: "Total de horas extras 2",
    name: "TOTAL_HORAS_EXTRAS_2",
  },
  TOTAL_HORAS_EXTRAS_3: {
    cod: 12,
    desc: "Total de horas extras 3",
    name: "TOTAL_HORAS_EXTRAS_3",
  },
  TOTAL_HORAS_ABONO: {
    cod: 14,
    desc: "Total de horas com Exceção/Abono",
    name: "TOTAL_HORAS_ABONO",
  },
  TOTAL_SALDO_FECHAMENTO: {
    cod: 17,
    desc: "Total de saldo horas do fechamento",
    name: "TOTAL_SALDO_FECHAMENTO",
  },
  TOTAL_SALDO_QUITAR_FECHAMENTO: {
    cod: 18,
    desc: "Total do saldo quitado no fechamento",
    name: "TOTAL_SALDO_QUITAR_FECHAMENTO",
  },
  TOTAL_HORAS_EXTRAS_EXCETO_FERIADO: {
    cod: 19,
    desc: "Total de horas extras, exceto feriados",
    name: "TOTAL_HORAS_EXTRAS_EXCETO_FERIADO",
  },
  TOTAL_SALDO_QUITAR_FECHAMENTO_POSITIVO: {
    cod: 20,
    desc: "Total de horas positivas, exceto feriados",
    name: "TOTAL_SALDO_QUITAR_FECHAMENTO_POSITIVO",
  },
  TOTAL_SALDO_QUITAR_FECHAMENTO_NEGATIVO: {
    cod: 21,
    desc: "Total de horas negativas, exceto feriados",
    name: "TOTAL_SALDO_QUITAR_FECHAMENTO_NEGATIVO",
  },
  TOTAL_DE_DIAS_COM_FALTA_EM_HORAS: {
    cod: 22,
    desc: "Total de dias com falta em horas",
    name: "TOTAL_DE_DIAS_COM_FALTA_EM_HORAS",
  },
  TOTAL_DE_HORAS_NORMAIS: {
    cod: 23,
    desc: "Total de Horas Normais",
    name: "TOTAL_DE_HORAS_NORMAIS",
  },
  ABONOS_DE_PONTO: {
    cod: 24,
    desc: "Abonos de Ponto",
    name: "ABONOS_DE_PONTO",
  },

  TOTAL_HORAS_EXTRAS_NOTURNA_1: {
    cod: 25,
    desc: "Total de horas extras noturnas 1",
    name: "TOTAL_HORAS_EXTRAS_NOTURNA_1",
  },
  TOTAL_HORAS_EXTRAS_NOTURNA_2: {
    cod: 26,
    desc: "Total de horas extras noturnas 2",
    name: "TOTAL_HORAS_EXTRAS_NOTURNA_2",
  },
  TOTAL_HORAS_NOTURNAS_SOMANDO_INTERVALO: {
    cod: 27,
    desc: "Total de horas noturnas somados do intervalo",
    name: "TOTAL_HORAS_NOTURNAS_SOMADOS_INTERVALO",
  },
  TOTAL_HORAS_EXTRAS_LIMITANDO_QUANTIA: {
    cod: 28,
    desc: "Total de horas extras, limitando a quantidade",
    name: "TOTAL_HORAS_EXTRAS_LIMITANDO_QUANTIA",
  },
  TOTAL_HORAS_EXTRAS_SALDO_LIMITADO_QUANTIA: {
    cod: 29,
    desc: "Total de horas extras, saldo remanescente limitando a quantidade",
    name: "TOTAL_HORAS_EXTRAS_SALDO_LIMITADO_QUANTIA",
  },
  TOTAL_HORAS_EXTRAS_ENTRE_QUANTIA: {
    cod: 30,
    desc: "Total de horas extras, entre dois intervalos",
    name: "TOTAL_HORAS_EXTRAS_ENTRE_QUANTIA",
  },
});

export const DOMINIO_TIPO_CAMPO = Object.freeze({
  TEXTO: {
    preDefinido: false,
    cod: 1,
    desc: "Texto",
    name: "TEXTO",
    icon: <BsInputCursorText size={20} />,
  },
  TEXTO_LONGO: {
    preDefinido: false,
    cod: 2,
    desc: "Texto Longo",
    name: "TEXTO_LONGO",
    icon: <BsTextarea size={20} />,
  },
  NUMERO: {
    preDefinido: false,
    cod: 3,
    desc: "Número",
    name: "NUMERO",
    icon: <ImSortNumbericDesc size={20} />,
  },
  EMAIL: {
    preDefinido: false,
    cod: 4,
    desc: "Email",
    name: "EMAIL",
    icon: <AiOutlineMail size={20} />,
  },
  TELEFONE: {
    preDefinido: false,
    cod: 5,
    desc: "Telefone",
    name: "TELEFONE",
    icon: <AiOutlinePhone size={20} />,
  },
  CAIXA_SELECAO: {
    preDefinido: true,
    cod: 6,
    desc: "Caixa de Seleção",
    name: "CAIXA_SELECAO",
    icon: <VscListSelection size={20} />,
  },
  CLASSIFICACAO: {
    preDefinido: false,
    cod: 7,
    desc: "Classificação",
    name: "CLASSIFICACAO",
    icon: <BsStarHalf size={20} />,
  },
  DATA: {
    preDefinido: false,
    cod: 8,
    desc: "Data",
    name: "DATA",
    icon: <BsCalendarDate size={20} />,
  },
  MULTIPLA_ESCOLHA: {
    preDefinido: true,
    cod: 9,
    desc: "Múltipla Escolha",
    name: "MULTIPLA_ESCOLHA",
    icon: <BsListOl size={20} />,
  },
});

export const DOMINIO_LAYOUT_EXPORTACAO_FOLHA = Object.freeze({
  DOMINIO_SISTEMAS: {
    cod: 1,
    desc: "Domínio Sistemas",
    name: "DOMINIO_SISTEMAS",
  },
  DATASUL_SISTEMAS: {
    cod: 2,
    desc: "DataSul Sistemas",
    name: "DATASUL_SISTEMAS",
  },
  PUBLICA_SENIOR: { cod: 3, desc: "Pública (Senior)", name: "PUBLICA_SENIOR" },
  SENIOR_SISTEMAS: { cod: 4, desc: "Senior Sistemas", name: "SENIOR_SISTEMAS" },
  CUCA_FRESCA: { cod: 5, desc: "Cuca Fresca", name: "CUCA_FRESCA" },
  QUESTOR_SISTEMAS: { cod: 6, desc: "Questor", name: "QUESTOR_SISTEMAS" },
  TOTVS_SISTEMAS: { cod: 7, desc: "Totvs", name: "TOTVS_SISTEMAS" },
  ALTERDATA_SISTEMAS: { cod: 8, desc: "Alterdata", name: "ALTERDATA_SISTEMAS" },
  LG_SISTEMAS: { cod: 9, desc: "LG Sistemas", name: "LG_SISTEMAS" },
  SULPROG_SISTEMAS: {
    cod: 10,
    desc: "Sulprog Sistemas",
    name: "SULPROG_SISTEMAS",
  },
  PROSOFT: { cod: 11, desc: "Prosoft", name: "PROSOFT" },
  AO3: { cod: 12, desc: "AO3", name: "AO3" },
  SCI: { cod: 13, desc: "SCI - Unico de folha", name: "SCI" },
  CONSISTEM: { cod: 14, desc: "Consistem", name: "CONSISTEM" },
  VISUAL_PRATICE: { cod: 15, desc: "Visual Practice", name: "VISUAL_PRATICE" },
  SHX: { cod: 16, desc: "SHX", name: "SHX" },
  PROTHEUS: { cod: 17, desc: "Protheus", name: "PROTHEUS" },
  SANKYA: { cod: 18, desc: "Sankhya", name: "SANKYA" },
  PROSIS: { cod: 19, desc: "Prosis", name: "PROSIS" },
  CONTA_MAIS: { cod: 20, desc: "Conta Mais", name: "CONTA_MAIS" },
  METADADOS: { cod: 21, desc: "Metadados", name: "metadados_abono" },
});

export const MONTHLY_PRICING_TABLE = [
  {
    name: "1º Plano: Avançado",
    price: "R$ 92,00",
    consultor: false,
    priceLabel:
      "Valor do plano mensal para equipes pequenas de 1 até 10 colaboradores",
    marginTop: 30,
    dominio: "PLANO_2",
    listItems: [
      {
        content: "Reconhecimento facial",
      },
      {
        content: "Geolocalização",
      },
      {
        content: "Batida de Ponto offline",
      },
      {
        content: "Acesso ao Aplicativo Para Tablet/Totens",
      },
      {
        content: "Distribuição de Documentos e Holerites",
      },
      {
        content: "Controle de Férias",
      },
    ],
  },
  {
    name: "2º Plano: Avançado",
    price: "R$ 138,00",
    dominio: "PLANO_3",
    consultor: false,
    priceLabel:
      "Valor do plano mensal para equipes médias de 11 até 20 colaboradores",
    marginTop: 5,
    listItems: [
      {
        content: "Reconhecimento facial",
      },
      {
        content: "Geolocalização",
      },
      {
        content: "Batida de Ponto offline",
      },
      {
        content: "Acesso ao Aplicativo Para Tablet/Totens",
      },
      {
        content: "Distribuição de Documentos e Holerites",
      },
      {
        content: "Controle de Férias",
      },
      {
        content: "API de Integração",
      },
    ],
  },
  {
    name: "3º Plano: Avançado por colaborador",
    price: "R$ 6,20",
    dominio: "PLANO_4",
    consultor: false,
    porColaborador: true,
    priceLabel:
      "Valor do plano mensal para equipes de 21 até 50 colaboradores.",
    marginTop: 0,
    listItems: [
      {
        content: "Reconhecimento facial",
      },
      {
        content: "Geolocalização",
      },
      {
        content: "Batida de Ponto offline",
      },
      {
        content: "Acesso ao Aplicativo Para Tablet/Totens",
      },
      {
        content: "Distribuição de Documentos e Holerites",
      },
      {
        content: "Controle de Férias",
      },
      {
        content: "API de Integração",
      },
      ,
    ],
  },
  {
    name: "4º Plano: Avançado",
    price: " - ",
    consultor: true,
    priceLabel: "Para equipes acima de 51 colaboradores",
    buttonLabel: "FALE COM UM CONSULTOR",
    dominio: "PLANO_5",
    url: "https://pontoicarus.com.br/contato",
    marginTop: 60,
    listItems: [
      {
        content: "Reconhecimento facial",
      },
      {
        content: "Geolocalização",
      },
      {
        content: "Batida de Ponto offline",
      },
      {
        content: "Acesso ao Aplicativo Para Tablet/Totens",
      },
      {
        content: "Distribuição de Documentos e Holerites",
      },
      {
        content: "Controle de Férias",
      },
      {
        content: "API de Integração",
      },
      ,
    ],
  },
];
